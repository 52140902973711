.tour-details-card {
  text-align: left;

  p {
    font-weight: 500;
  }
}

#chartjs-tooltip {
  background-color: rgba(229, 229, 229, 1);
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: black;

  span {
    font-weight: bold;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.chartCard {
  height: 400px;
}

.statsTab .ant-pagination {
  display: none;
}

.errorMessage {
  margin-bottom: 50px;
}

.app-hereMapsMap{
  width: 640px;
  height: 480px;
}

.tablesAndCharts {
  height: 86vh;
  overflow-y: auto;

  .ant-card {
    margin-bottom: 20px;
  }
}

.App {
  min-height: calc(100vh - 180px);
}

.content {
  margin: 30px 40px 30px 40px;
}

.app-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

.app-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.site-page-header {
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-page-header-heading {
  justify-content: center;
}

.date-input {
  width: 200px;
}

.shipperOrCarrier {
  margin-left: 10px;
}

.is-carrier {
  margin-right: 30px !important;
}

.editCompany {
  margin-left: 25px;
}

.copyCompany {
  margin-left: 10px;
}

.newCompany {
  text-align: center;
  button {
    margin-top: 10px;
    margin-bottom: 7px;
  }
}

.mainMenu {
  text-align: end;

  dropdown {
    margin-right: 20px;
  }
}

.dropdownLink {
  color: inherit;
  margin-right: 20px;
}

.search-input {
  width: 475px;
}
