.vehicle-details-infobubble {
  .show_last {
    width: 100%;
    margin-top: 10px;
  }
  .H {
    &_bubble {
      &_root {
        min-width: 200px;
        max-width: 200px;
        margin: 10px;
      }
      &_wrapper {
        max-width: 250px;
        max-height: 599px;
      }
      &_content {
        padding: 0;
        &--company {
          font-size: 12px;
          text-align: left;
          color: #5E6069;
          margin-top: 2px;
        }
        &--vehicle-id {
          font-size: 16px;
          color: #489ed7;
          text-decoration: none;
        }
        &--vehicle-icon {
          position: relative;
          top: -4px;
          margin-right: 5px;
        }
        &--details {
          margin-top: 5px;
          width: 100%;
          font-size: 12px;
          line-height: 20px;
          font-family: Roboto, Arial, sans-serif;
          tr {
            line-height: 14px;
            vertical-align: top;
            td {
              padding: 3px 0;
              &:first-child {
                width: 75px;
              }
              &:last-child {
                padding-left: 5px;
              }
            }
          }
        }
        &--label {
          color: #5E6069;
          display: inline-block;
          font-weight: 600;
        }
        &--data {
          color: #5E6069;
          display: inline-block;
          font-weight: 400;
        }
      }
    }
  }
}

.above-marker-infobubble {
  .H_ib_body {
    transform: translate(50%, -56px);
  }
}

.above-circle-infobubble {
  .H_ib_body {
    transform: translate(50%, -16px);
  }
}

.above-infobubble {
  .H_ib_body {
    transform: translate(50%, -5px);
  }
}

.trip-start-infobubble {
  .H_ib_close {
    display: none;
  }
  .H_ib_content {
    margin: 10px !important;
  }
}

.trip-info-infobubble {
  .close_last {
    width: 100%;
    margin-top: 10px;
  }

  .pin-icon {
    min-width: 8px;
  }

  .H {
    &_bubble {
      &_root {
        min-width: 170px;
        max-width: 170px;
        font-family: Roboto, Arial, sans-serif;
      }
      &_wrapper {
        max-width: 249px;
        max-height: 599px;
      }
      &_header {
        display: flex;
        align-items: center;
        &--title {
          font-size: 12px;
          font-weight: 500;
          color: #4f4f4f;
          margin-top: 2px;
          margin-left: 3px;
        }
      }
      &_content {
        padding: 0;
        &--company {
          font-size: 10px;
          text-align: left;
          color: #4f4f4f;
          margin-top: 2px;
        }
        &--vehicle-id {
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
          color: #4f4f4f;
          text-decoration: none;
        }
        &--label {
          color: #5E6069;
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 4px;
          margin-top: 4px;
        }
        &--data {
          color: #5E6069;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 0;
        }
        &--info-data {
          margin-left: 4px;
          margin-top: 4px;
        }
        &--close-last {
          margin-bottom: 0;
          margin-top: 7px;
          text-decoration: underline;
        }
        &--highlighted-value {
          font-weight: 700;
        }
        &--section-icon {
          width:21px;
          text-align:center;
        }
        &--link {
          text-decoration: underline;
        }
        &--coords {
          display:flex;
          align-items:center;
          margin-top: 2px;
        }
        div.horizontal-line {
          margin-top: 0;
          margin-bottom: 4px;
          border-color: #f2f2f2;
        }
      }
    }
  }
};
